import React from "react";
import logo from "./assets/images/logo_full.png";
import "./App.css";

function App() {
  return (
    <div className="home">
      <header>
        <div>
          <img src={logo} alt="visualbasement_logo" height="100%" />
        </div>
      </header>
      <div className="body-bg"></div>
      <section>
        <p>
          <span>#</span>underconstruction
        </p>
        <h1>Coming Soon</h1>
        <h4>
          Email us at{" "}
          <a href="mailto:dev@visualbasement.com?Subject=Inquiry" target="_top">
            dev@visualbasement.com
          </a>{" "}
          for inquiries.
        </h4>
      </section>
      <footer>
        <p>Copyright © 2019-2020 VisualBasement. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default App;
